<template>
  <app-nav @onload="onLoad" />
  <div v-if="loading" class="loading"></div>
  <div v-else class="hasan">
    <hasan-query :user="user" />
  </div>
</template>

<script>
// @ is an alias to /src
import AppNav from '@/components/AppNav.vue'
import HasanQuery from '@/components/HasanQuery.vue'

export default {
  name: 'Hasan',
  components: {
    AppNav,
    HasanQuery
  },
  data() {
		return {
      user: false,
      loading: true
		}
	},
  methods: {
    onLoad(user) {
      this.user = user;
      this.loading = false;
    }
  }
}
</script>

<style scoped>
div.hasan {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
