<template>
<div class="hasan-query" v-if="!compact">
  <h2>検索条件</h2>
  <fieldset>
  <label>
  <span class="label">公示日</span>
  <input type="date" v-model="b" />～<input type="date" v-model="e" />
  </label>
  </fieldset>
  <fieldset>
  <!--
  <label>
  <span class="label">種別</span>
  <select v-model="t" v-bind:disabled="!user.expire">
    <option value="">-- 指定なし --</option>
    <option v-for="type in types" :key="type">{{ type }}</option>
  </select>
  </label>
  -->
  <label>
  <span class="label">裁判所</span>
  <select v-model="c" v-bind:disabled="!user.expire">
    <option value="">-- 指定なし --</option>
    <option v-for="court in courts" :key="court">{{ court }}</option>
  </select>
  </label>
  </fieldset>
  <fieldset>
  <label>
  <span class="label">名前</span>
  <input type="text" v-model="n" @input="doInput" />
  </label>
  <label>
  <span class="label">住所</span>
  <input v-bind:disabled="!user.expire" type="text" v-model="a" @input="doInput" />
  </label>
  <button @click="search" :disabled="disableSearch">検索 <fa icon="search" /></button>
  </fieldset>

  <fieldset v-if="user.expire">
  <label>
  <span class="label">ラベル</span>
  <input type="text" v-model="label" />
  <button @click="tripwire" :disabled="disableSearch">通知条件を登録 <fa icon="plus" /></button>
  </label>
  </fieldset>
</div>
<div v-if="loading" class="loading"></div>
<div class="hasan-results" v-if="count > 0">
  <div>全{{ count }}件<span v-if="over">以上</span>
  {{ o }}件目から{{ o + items.length }}件目
  <button @click="searchAgain" v-if="compact">検索条件変更 <fa icon="search" /></button>
  </div>
  <div>
  <button :disabled="o <= 0" @click="first">最初</button>
  <button :disabled="o <= 0" @click="previous">前へ</button>
  <a v-for="page in pages" :key="page" @click="go(page)" href="#">{{ page }}</a>
  <button :disabled="o + items.length >= count" @click="next">次へ</button>
  <button :disabled="o + items.length >= count" @click="last">最後</button>
  </div>
  <div class="scroll">
  <strong>掲載者への差別的取り扱いを目的とした利用は禁止します</strong>
  <table class="list">
    <thead>
      <tr>
      <th>公示日・掲載号</th>
      <th>裁判所・事件</th>
      </tr>
    </thead>
    <tbody v-for="item in items" :key="item.id">
      <tr>
      <td>{{ item.published }}<br/>{{ item.kanpo }} {{ item.issue }} {{ item.page }}頁</td>
      <td>{{ item.court }}<br/>{{ item.caseid }} {{ item.type }}</td>
      </tr>
      <tr>
      <td colspan="2">
      <span class="name">{{ item.name }}</span> <span class="name" v-if="item.delegate"> / {{ item.delegate }}</span><br />
      {{ item.address }}
      </td>
      </tr>
    </tbody>
  </table>
  </div>
</div>
<div v-else class="noresults">
  {{ message }}
</div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
	name: 'HasanQuery',
  props: [
    'user'
  ],
	data() {
		return {
      types: [],
      courts: [],
			b: this.$route.query.b,
			e: this.$route.query.e,
			t: this.$route.query.t,
			c: this.$route.query.c,
			n: this.$route.query.n,
			a: this.$route.query.a,
      o: 0,
      label: '',
      disableSearch: true,
      compact: true,
      limit: 0,
			items: [],
			count: 0,
      message: '',
      pages: [],
      loading: true
		}
	},
	mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
		if (!this.t) {
			this.t = '';
		}
		if (!this.c) {
			this.c = '';
		}
    this.doInput();
    // メタ情報取得
    axios.get(config.API+'hasan.php', {
      params: {
        r: this.user.range,
        m: 'meta'
      }
    }).then(res => {
      this.types = res.data.types;
      this.courts = res.data.courts;

      if (this.$route.query.o !== undefined) {
        // 自動的に検索
        this.o = Number(this.$route.query.o);
        this.load();
      }
      else {
        this.b = res.data.begin;
        this.e = res.data.end;
        this.compact = false;
        this.loading = false;
      }
    });
	},
	methods: {
    doInput() {
      this.disableSearch = (!this.n && !this.a);
    },
    tripwire() {
      if (!this.label) {
        this.label = this.n ? this.n : this.a;
      }
      axios.post(config.API+'tripwire.php', {
        action: 'add',
        label: this.label,
        query: {
          t: this.t,
          c: this.c,
          n: this.n,
          a: this.a
        }
      },
      {
        withCredentials: true
      }).then(res => {
        alert(res.data.message);
      });
    },
    load() {
      this.compact = true;
      this.loading = true;
      let params = new URLSearchParams();
      params.append('r', this.user.range);
      if (this.b) params.append('b', this.b);
      if (this.e) params.append('e', this.e);
      if (this.t) params.append('t', this.t);
      if (this.c) params.append('c', this.c);
      if (this.n) params.append('n', this.n);
      if (this.a) params.append('a', this.a);
      params.append('o', this.o);
      window.history.replaceState({}, '', `${location.pathname}?${params.toString()}`);
			axios.get(config.API+'hasan.php', {
				params: params
			}).then(res => {
        this.loading = false;
        if (res.data.status != 'success') {
          this.message = res.data.message;
          this.count = 0;
          this.limit = 0;
          this.compact = false;
        }
        else {
          this.items = res.data.items;
          this.count = res.data.count;
          this.over = res.data.over;
          this.limit = res.data.limit;
          this.message = res.data.message;
          this.pages = [];
          let page = (this.o / this.limit) - 2;
          if (page >= 0) {
            this.pages.push(page + 1);
          }
          page = (this.o / this.limit) - 1;
          if (page >= 0) {
            this.pages.push(page + 1);
          }
          page = (this.o / this.limit) + 1;
          if (page * this.limit < this.count) {
            this.pages.push(page + 1);
          }
          page = (this.o / this.limit) + 2;
          if (page * this.limit < this.count) {
            this.pages.push(page + 1);
          }
          this.compact = this.count > 0;
        }
			});
		},
    search() {
      this.o = 0;
      this.load();
    },
    next() {
      this.o += this.items.length;
      this.load();
    },
    previous() {
      this.o -= this.limit;
      this.load();
    },
    go(page) {
      this.o = (page - 1) * this.limit;
      this.load();
    },
    first() {
      this.o = 0;
      this.load();
    },
    last() {
      this.o = Math.floor(this.count / this.limit) * this.limit;
      this.load();
    },
    searchAgain() {
      this.compact = false;
    },
    onResize() {
      let hs = document.getElementById('hasan-scroll');
      if (hs) {
        hs.style.height = (window.innerHeight - hs.getBoundingClientRect().top)+'px';
      }
    }
	},
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  updated() {
    this.onResize();
  }
}
</script>

<style scoped>
.hasan-query  {
	border: 4pt solid #144E94;
  text-align: center;
  margin: 0 auto;
  width: 360pt;
  max-width: 100%;
}

.hasan-query input[type=text],
.hasan-query input[type=date],
.hasan-query select {
	width: 8em;
	margin-right: 4pt;
  border: none;
  border-radius: 10pt;
  padding: 2pt;
  background-color: #EEEDF1;
}
.hasan-query input[type=date] {
  width: 10em;
}
.hasan-query fieldset {
	display: block;
  vertical-align: middle;
  margin: 4pt;
}
.hasan-query fieldset * {
  vertical-align: middle;
}
.hasan-query label {
	white-space: nowrap;
}
.hasan-query .label {
	display: inline-block;
	font-weight: bold;
	text-align: center;
	width: 4em;
}
.hasan-query .radios {
	font-size: smaller;
}
.hasan-results {
  padding: 4pt;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.hasan-results button {
  margin: 0 4pt;
}
.hasan-results a {
  display: inline-block;
  text-align: center;
  width: 4ex;
}

.hasan-results table td[colspan] {
  white-space: normal;
}
.hasan-results span.name {
  white-space: nowrap;
  font-weight: bold;
}
.noresults {
  padding: 1em;
  text-align: center;
  font-weight: bold;
}
</style>
